<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.isFeatured`]="{ item }">
                        <v-chip
                            small
                            label
                            :color="
                                item.isFeatured !== false
                                    ? 'success'
                                    : 'warning'
                            "
                        >
                            {{
                                $t(
                                    'labels.' +
                                        (item.isFeatured !== false
                                            ? 'yes'
                                            : 'no')
                                )
                            }}
                        </v-chip>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.translations[currentLocale].name"
                            :entity-id="item.id"
                            :api="api"
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>
            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import Headers from '@/pages/tags/mixins/Headers'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],

    data() {
        return {
            api: RestApiType.TAGS,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.translations')
        },
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
    },

    methods: {
        getEditLink(item, tab = 0) {
            return {
                name: routeType.TAG_EDIT,
                params: { id: item.id },
                query: { tab: tab },
            }
        },
    },
}
</script>
