<template>
    <div>
        <page-title>{{ $t('menu.residential_canteen_addresses') }}</page-title>
        <users-table ref="table" @company-filter="companyFilter = $event">
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.RESIDENTIAL_CANTEEN_ADDRESSES_CREATE"
                ></create-button>
            </template>
        </users-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import UsersTable from '@/components/pages/residentialCanteens/addresses/Table'
import CreateButton from '@/components/form/Actions/CreateButton'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        UsersTable,
        CreateButton,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            companyFilter: null,
        }
    },
    computed: {
        company() {
            return this.isSuperAdmin ? this.companyFilter : this.activeCompany
        },
        companySelect() {
            return this.isSuperAdmin
        },
    },
}
</script>
