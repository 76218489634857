export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: `tagGroup.translations[${this.currentLocale}].name`,
                    text: this.$t('labels.tag_group'),
                    type: 'string',
                },
                {
                    value: `translations[${this.currentLocale}].name`,
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'isFeatured',
                    text: this.$t('labels.featured'),
                    type: 'string',
                },
            ]
        },
    },
}
