export default {
    computed: {
        headers() {
            const fields = [
                {
                    value: 'restaurant.name',
                    text: this.$t('labels.restaurant'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'address.name',
                    text: this.$t('labels.address'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'deliveryTime',
                    text: this.$t('labels.delivery_time'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'deliveryFee',
                    text: this.$t('labels.delivery_fee'),
                    sortable: true,
                    type: 'string',
                },
            ]

            return fields
        },
    },
}
