var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-select":true,"row-action":true},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.cutoffTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.cutoffTime))+" ")]}},{key:"item.deliveryTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryTime))+" ")]}},{key:"item.pickupOffset",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryTime,-Number(item.pickupOffset)))+" ")]}},{key:"item.isRecurring",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isRecurring ? 'Yes' : 'No')+" ")]}},{key:"item.userRoles",fn:function(ref){
var item = ref.item;
return [(item.userRoles && item.userRoles[0])?[_vm._v(" "+_vm._s(_vm.$t('roles.' + item.userRoles[0].role))+" "),_c('br'),(item.userRoles[0].company)?_c('span',{staticClass:"lower"},[_vm._v(" "+_vm._s(item.userRoles[0].company.name)+" ")]):_vm._e(),(item.userRoles[0].restaurant)?_c('span',{staticClass:"lower"},[_vm._v(" "+_vm._s(item.userRoles[0].restaurant.name)+" ")]):_vm._e()]:_vm._e()]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }