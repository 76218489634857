var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{staticClass:"mt-4",attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters,"hide-search-box":""},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"canteen-name text-truncate",class:_vm.isEditable(item) ? '' : 'grey--text',attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.cutoffTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.cutoffTime))+" ")]}},{key:"item.deliveryTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryTime))+" ")]}},{key:"item.pickupOffset",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryTime,-Number(item.pickupOffset)))+" ")]}},{key:"item.preCreateDeliveryEnabled",fn:function(ref){
var item = ref.item;
return [(item.preCreateDeliveryEnabled)?_c('edit-button',{attrs:{"icon":"more_time","to":{
                        name: _vm.routeType.DELIVERY_LIST,
                        query: {
                            'preCreatedFor[type]': 'residential_canteen',
                            'preCreatedFor[id]': item.id,
                        },
                    }}}):_vm._e()]}},{key:"item.isRecurring",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":item.isRecurring ? 'green' : ''}},[_vm._v(" "+_vm._s(item.isRecurring ? 'Yes' : 'No')+" ")])]}},{key:"rowActions",fn:function(ref){
                    var item = ref.item;
return [_c('edit-button',{attrs:{"icon":_vm.isEditable(item) ? 'edit' : 'preview',"tooltip":_vm.isEditable(item) ? '' : _vm.$t('actions.view'),"to":_vm.getEditLink(item)}}),(
                        item.preCreateDeliveryEnabled === false &&
                        _vm.$date(item.cutoffTime) > _vm.$date()
                    )?_c('table-button',{attrs:{"tooltip":_vm.$t('actions.send_pre_delivery'),"color":"blue-grey","icon":"two_wheeler","approve-color":"error"},on:{"execute":function($event){;(_vm.dialog = true), (_vm.selectedItem = item)}}}):_vm._e(),(_vm.isEditable(item))?_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}}):_vm._e()]}}],null,true)}),_c('confirm-modal',{attrs:{"title":_vm.$t('texts.request_courier'),"text":_vm.$t('texts.do_you_want_request_courier', {
                    item: ("" + _vm.selectedItemIdentifier),
                }),"order-id":_vm.selectedItemIdentifier,"approve-color":"green","approve-text":_vm.$t('actions.confirm')},on:{"onApprove":_vm.sendPreDelivery,"onCancel":function($event){;(_vm.selectedItem = null), (_vm.dialog = false)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }