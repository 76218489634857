export default {
    computed: {
        headers() {
            const fields = [
                {
                    value: 'identifier',
                    text: this.$t('labels.identifier'),
                    type: 'string',
                },
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'cutoffTime',
                    text: this.$t('residential_canteen.cutoff_time'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'deliveryTime',
                    text: this.$t('residential_canteen.delivery_time'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'pickupOffset',
                    text: this.$t('residential_canteen.pickup_time'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'address.name',
                    text: this.$t('residential_canteen.canteen_address'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'isRecurring',
                    text: this.$t('residential_canteen.is_recurring'),
                    type: 'string',
                },
                {
                    value: 'preCreateDeliveryEnabled',
                    text: this.$t('labels.canteen_pre_create_delivery_enabled'),
                    align: 'center',
                    type: 'boolean',
                },
            ]

            return fields
        },
    },
}
