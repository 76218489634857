<template>
    <div>
        <page-title>{{ $t('tags.list_page_title') }}</page-title>
        <tags-table ref="table" @company-filter="companyFilter = $event">
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.TAG_CREATE"
                    tabbed
                ></create-button>
            </template>
        </tags-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import TagsTable from '@/components/pages/tags/Table'
import CreateButton from '@/components/form/Actions/CreateButton'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        TagsTable,
        CreateButton,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            companyFilter: null,
        }
    },
}
</script>
